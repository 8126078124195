<template>
  <div
    class="menu-item"
    :class="[{ active: isActiveClass }, item.class]"
    role="button"
    :aria-pressed="isActiveClass"
    @click.prevent="openLink(item)"
  >
    <div class="menu-item__icon" :class="{ logged: isLogged }">
      <BaseImg :src="isActiveClass ? item.iconActive : item.icon" class="img-fluid" />
    </div>
    <div class="d-block menu-item__text">
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { PAGE_URLS } from '~/config/page-url'
import { useHeader } from '~/composables/useHeader'
import { IMenuItemMb } from '~/types/home'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const route = useRoute()

const { onClickAuthor, MODAL_TYPES } = useHeader()

const props = withDefaults(defineProps<{ item: IMenuItemMb }>(), {})

const isActiveClass = computed(() => {
  const { fullPath, path, query } = route

  // Ensure the current item's link matches the URL path and query.tab
  if (path === '/account' && props.item.link === '/account') {
    // Specific tab logic
    if (query.tab === 'deposit') {
      return props.item.name === 'Nạp Rút'
    } else if (query.tab === 'history') {
      return props.item.name === 'Lịch Sử'
    } else if (query.tab === 'overview') {
      return props.item.name === 'Tài Khoản'
    }
  }

  // Default match for other pages (non-account paths)
  return route.fullPath === props.item.link
})

const openLink = (item: IMenuItemMb) => {
  if (isLogged.value || !item.loginRequired) {
    navigateTo(item.link)
  } else {
    onClickAuthor(MODAL_TYPES.LOGIN, 'login')
  }
}
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/common/menu-bottom/menu-item.scss"></style>
