<template>
  <div id="tooltip-menu-bottom" class="menu-bottom">
    <client-only>
      <div class="menu-bottom__content" :class="{ 'menu-bottom__active': isActiveClass }">
        <MenuItem v-for="(item, index) in menus" :key="item.icon" :item="item" :class="`menu-item--${index}`" />
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import MenuItem from './menu-item.vue'
import { menuBottom } from '~/constants/menu'
import { PAGE_URLS } from '~/config/page-url'
const route = useRoute()
const menus = computed(() => menuBottom(route?.path === (PAGE_URLS.SPORT || PAGE_URLS.HOME)))

const isActiveClass = computed(() => {
  const { path, query } = route
  const isAccountTab = (tab: string) => path === '/account' && query.tab === tab
  const isCodepayDeposit = isAccountTab('deposit')
  return isCodepayDeposit
})

</script>
<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/index.scss" />
