<template>
  <div class="home-mb">
    <div
      class="home-mb__main"
    >
      <div
        id="home"
        class="home-mb__main"
      >
        <slot name="main" />
      </div>
    </div>
  </div>
</template>

<script setup>
onMounted(() => {
  document.body.style.overflow = 'hidden'
})

onBeforeUnmount(() => {
  document.body.style.overflow = ''
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/index.scss"></style>
