<template>
  <div class="hero-banner">
    <div class="">
      <div v-if="bannerData && bannerData.length" :pagination="true" class="hero-banner">
        <Swiper ref="banner" v-bind="swiperOption" class="mySwiper">
          <SwiperSlide v-for="(item, idx) in bannerData" :key="item.id" class="swiper-slide">
            <BannerItem :item="item" :index="idx" @click="handleClickBanner(item)" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow } from 'swiper'
import BannerItem from './banner-item'
import useHeroBanner from '~/composables/home/useHeroBanner'

const { bannerData, fetchBanner, handleClickBanner } = useHeroBanner()

const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  pagination: {
    clickable: true
  },
  modules: [Autoplay, Pagination, EffectCoverflow]
})

onMounted(() => {
  fetchBanner()
})
</script>

<style scoped lang="scss">
@import 'assets/scss/components/mobile/pages/home/hero-banner/index.scss';
</style>
