<template>
  <MobileLayoutsHome>
    <template #main>
      <div class="layout-game">
        <div class="game-wrapper">
          <div class="game-wrapper__content" :class="{ 'margin-item': marginItem }">
            <div
              id="target_element"
              class="game-wrapper__content--left"
              :style="{
                height: route?.fullPath === '/the-thao' ? 'auto' : '100%'
              }"
            >
              <div v-if="TABS" id="left-menu-container" ref="listRef" class="left-menu">
                <nuxt-link
                  v-for="tab in TABS"
                  :id="`left-menu-${tab?.key}`"
                  ref="itemRef"
                  :key="tab.key"
                  class="tab-link"
                  :class="[tab.key, { active: isActiveTab(tab) }]"
                  :to="getHref(tab.key)"
                >
                  <BaseImg :src="getTabIcon(tab)" />
                  <div v-html="tab.title" />
                </nuxt-link>
              </div>
            </div>
            <div ref="listRightRef" class="game-wrapper__content--right">
              <nuxt-page />
            </div>
          </div>
        </div>
      </div>
    </template>
  </MobileLayoutsHome>
</template>

<script setup lang="ts">
import { getLeftMenu } from '~/resources/menu-left'
import MobileLayoutsHome from '~/components/mobile/layouts/home.vue'
import { isValidLivecasinoUrl } from '~~/helper'
import { PAGE_URLS } from '~~/config/page-url'

const route = useRoute()
const router = useRouter()
const { TABS } = getLeftMenu()
const listRef = ref(null)
const itemRef = ref<(HTMLElement | null)[]>([])
const listRightRef = ref(null)
const marginItem = ref(false)
const { $device } = useNuxtApp()

const isActiveTab = (tab: any) => {
  switch (tab.active) {
    case 'sports':
      return route.fullPath === '/the-thao'
    case 'livecasino-type':
      return isValidLivecasinoUrl(route.fullPath)
    case 'game-type':
      return tab.key === route.params?.type
  }
}

const getTabIcon = (tab: any) => {
  return isActiveTab(tab) ? tab.iconImage : tab.iconImageDefault
}

const scrollToActive = (delay = 50, index: number) => {
  setTimeout(() => {
    const itemRef = document?.querySelectorAll('.tab-link')
    if (itemRef) {
      const item = itemRef[index] as HTMLElement | null
      if (item) {
        item.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }, delay)
}

const handleScrollWhenBack = (key: string) => {
  setTimeout(() => {
    const categoryAlias = key
    if (categoryAlias) {
      const categoryActiveElement: any = document.querySelector(`#left-menu-${categoryAlias}`)
      const categoryActiveInfor = {
        offsetTop: categoryActiveElement?.offsetTop || 0,
        height: categoryActiveElement?.clientHeight || 0
      }
      const listCategoryElementProvider: any = document.querySelector('#left-menu-container')
      const scrollPosition =
        categoryActiveInfor?.offsetTop - listCategoryElementProvider?.clientHeight / 2 + categoryActiveInfor?.height / 2
      if (listCategoryElementProvider) {
        listCategoryElementProvider.scrollTop = scrollPosition
      }
    }
  }, 200)
}

const height = ref<number>()

const getHeightContent = async (): Promise<number> => {
  const getHeaderElement = (await document.getElementById('header-mobile')?.clientHeight) ?? 48
  const getHeroBannerElement = (await document.getElementById('hero-banner')?.clientHeight) ?? 128
  const getMenuBottomElement = (await document.querySelectorAll('#tooltip-menu-bottom > #menu-bottom')[0]?.clientHeight) ?? 60

  const androidHeight = await (window.innerHeight - (getHeroBannerElement + getHeaderElement + getMenuBottomElement))
  const iOSHeight = await (document.documentElement.clientHeight - (getHeroBannerElement + getHeaderElement + getMenuBottomElement))

  return $device.isIos ? iOSHeight : androidHeight
}

onMounted(async () => {
  height.value = await getHeightContent()
  const bodyElement = await document.querySelector('body')
  if (isIOS()) {
    if (bodyElement !== null) {
      bodyElement.classList.add('fix-screen')
    }
  }
  window.addEventListener('resize', async () => {
    height.value = await getHeightContent()
  })
  if (bodyElement !== null) {
    bodyElement.style.paddingBottom = '0px'
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', () => {})
  const bodyElement = document.querySelector('body')
  if (isIOS()) {
    if (bodyElement !== null) {
      bodyElement.classList.remove('fix-screen')
    }
  }
  if (bodyElement !== null) {
    bodyElement.style.paddingBottom = ''
  }
})

function findTabByRoute(tab: typeof TABS[number]) {
  const { name, params } = route
  const validNames = ['the-thao', 'livecasino-type', 'game-type']
  if (typeof name === 'string' && validNames.includes(name) && tab.key === params?.type) {
    return tab.index - 1
  }
  return undefined
}

watch(
  route,
  () => {
    TABS.find((e) => {
      const tab = findTabByRoute(e)
      if (tab) {
        scrollToActive(150, tab)
      } else {
        scrollToActive(150, 0)
      }
      return false
    })
  },
  {
    immediate: true
  }
)

onMounted(() => {
  window.addEventListener('popstate', function () {
    const tab = TABS.find((e) => isActiveTab(e))
    handleScrollWhenBack(tab?.key)
  })
})

const getHref = (key: string) => {
  return key === 'livecasino' ? PAGE_URLS.CASINO : key === 'sports' ? PAGE_URLS.SPORT : `${PAGE_URLS.GAME}/${key}`
}

const isIOS = () => {
  const ua = navigator?.userAgent
  return /iPhone|iPad|iPod/i.test(ua)
}
</script>

<style lang="scss" scoped src="~/assets/scss/components/mobile/layouts/layout-game.scss"></style>
