<template>
  <div id="header-mobile" class="header">
    <CommonBaseImg
      id="logo-mobile"
      class="logo"
      :src="`${PATH_BRAND}logo.svg`"
      alt="logo"
      @click="onPressLogo"
    />
    <div class="header-button">
      <MobileHeaderLoginButton v-if="!currentUser" />
      <MobileHeaderUserLogged v-else />
    </div>
  </div>
</template>
<script setup lang="ts">
import { PATH_BRAND } from '~/constants/path'
import CommonBaseImg from '~/components/common/base-img'
import MobileHeaderLoginButton from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { useHeader } from '~/composables/useHeader'
import { PAGE_URLS } from '~/config/page-url'
const { currentUser } = useHeader()
const router = useRouter()
const route = useRoute()

const onPressLogo = () => {
  if (route.path === PAGE_URLS.SPORT) {
    scrollHomeElementToTop()
  } else {
    router.push(PAGE_URLS.HOME)
  }
}

const scrollHomeElementToTop = () => {
  const scrollElementToTopByClass = (className: string) => {
    document.querySelector(className).scrollTo({ top: 0, behavior: 'smooth' })
  }
  scrollElementToTopByClass('.game-wrapper__content--right')
  scrollElementToTopByClass('.left-menu')
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/index.scss" />
